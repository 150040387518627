import { Dialog, DialogBody, DialogHeader, Button, Textarea, Collapse } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { addComment, commentHistory } from "../../../redux/thunk/leadmanagement";
import { useEffect, useState } from "react";

const Comments = ({ visible, onClose, leadId }) => {

  const [tableData, setTableData] = useState();
  const state = useSelector((state) => state.LeadSlice.commentHistory);

  const [showComment, setShowComment] = useState(false);

  useEffect(() => {
    setTableData(state?.result);
  }, [state]);

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(addComment({ leadId, comment: e.target[0].value }))
    onClose()
  }
  useEffect(() => {
    if(visible) dispatch(commentHistory(leadId));
    setShowComment(false)
  }, [visible])

  return (
      <Dialog open={visible} handler={onClose}>
        <DialogHeader className="flex justify-between">Add Comments</DialogHeader>
        <DialogBody>
          <form onSubmit={submitHandler}>
            <Textarea size="lg" label="Comment" color="blue" />
            <div className="flex justify-center gap-3 mt-2">
              <Button color="cyan" onClick={() => setShowComment(!showComment)}>Comment History</Button>
              <Button color="green" type="submit">Submit</Button>
            </div>
          </form>
          <Collapse open={showComment}>
            <table className="w-full border border-collapse min-w-full table-auto text-center  mt-3">
              <thead className="border-b border-blue-gray-100 bg-cyan-400 text-white p-4">
                <th className="border py-1">Comment</th>
                <th className="border">Commented At</th>
                <th className="border">Commented By</th></thead>
              <tbody >
                {tableData && tableData.map((item, i) => {
                  const options = { day: 'numeric', month: 'short', year: 'numeric' };
                  const formattedDate = new Date(item.commentedAt).toLocaleDateString('en-US', options);
                  return (
                    <tr key={i}>
                      <td className="border-r py-1">{item.comment}</td>
                      <td className="border-r">{formattedDate}</td>
                      <td className="border-r">SuperAdmin</td>
                      {/* <td className="border-r">{item.commentedBy}</td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Collapse>
        </DialogBody>
      </Dialog>
  )
}

export default Comments;
