import { createAsyncThunk } from "@reduxjs/toolkit";
import {axios} from "../../utils/axios"
import { baseURL, } from "../../constants/defaultValues";

export const searchLead=createAsyncThunk('searchLead',async(payload)=>{
    try{
        const res=await axios.get(baseURL+`/lead/searchLead?query=${payload.value}&${payload?`pageSize=${payload.pageSize}&pageIndex=${payload.pageIndex}`:""}`)
        return res.data
    }catch(error){
        throw error
    }
})

export const updateLead=createAsyncThunk('updateLead',async(payload)=>{
    try{
        const res=await axios.post(baseURL+'/lead/updateLead',payload)
        return res.data
    }catch(error){
        throw error
    }
})

export const changeStatus=createAsyncThunk('changeStatus',async(payload)=>{
    try{
        const res=await axios.post(baseURL+`/lead/changeStatus`,payload)
        return res.data
    }catch(error){
        throw error
    }
})

export const getallLead=createAsyncThunk('getallLead',async(payload)=>{
    try{
        const query=`${payload&&payload.type==='status'?'?status='+payload.status+"&":(payload&&payload.type==='telecaller'?'?telecaller='+payload.telecaller+'&':'')}`
        const res=await axios.get(baseURL+`/lead/allLeads${payload.type?query:'?'}pageSize=${payload.pageSize}&pageIndex=${payload.currentPage}`)
        return res.data
    }catch(error){
        throw error
    }
})

export const addLead=createAsyncThunk('addLead',async(payload)=>{
    try{
        const res=await axios.post(baseURL+'/lead/newLead',payload)
        return res.data
    }catch(error){
        throw error
    }
})

export const addComment=createAsyncThunk('addComment',async(payload)=>{
    try{
        const res=await axios.post(baseURL+'/lead/addComment',payload)
        return res.data
    }catch(error){
        throw error
    }
})

export const commentHistory=createAsyncThunk('commentHistory',async(payload)=>{
    try{
        const res=await axios.get(baseURL+`/lead/viewComment?leadId=${payload}`)
        return res.data
    }catch(error){
        throw error
    }
})

export const assignToTelecaller=createAsyncThunk('assignToTelecaller',async(payload)=>{
    try{
        const res=await axios.post(baseURL+'/lead/assignToTelecaller',payload)
        return res.data
    }catch(error){
        throw error
    }
})
