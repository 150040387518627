import { combineReducers } from 'redux';
import LoginReducer from '../slice/loginSlice'
import DeviceSlice from '../slice/deviceSlice'
import ElectricianSlice from '../slice/electricianSlice'
import DashboardSlice from '../slice/dashboardSlice';
import UserSlice from '../slice/userSlice';
import LeadSlice from '../slice/leadSlice'
import RequestSlice from '../slice/requestSlice';
import AnalyticsSlice from '../slice/analyticsSlice';

export default combineReducers({
    LoginReducer,RequestSlice ,DeviceSlice,UserSlice,LeadSlice,ElectricianSlice, DashboardSlice, AnalyticsSlice });