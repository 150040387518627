import { createAsyncThunk } from "@reduxjs/toolkit";
import {axios} from "../../utils/axios"
import { baseURL, localHost } from "../../constants/defaultValues";


export const getAllDevices=createAsyncThunk('getAllDevices',async(payload)=>{
    
    try{
        const query= `${(payload&&payload.type==='deviceType')?'?deviceType='+payload.device+"&":''}`
        const res=await axios.get(baseURL+`/devices/getall${payload.type?query:"?"}pageSize=${payload.pageSize}&pageIndex=${payload.currentPage}`)
        return res.data
    }catch(error){
        throw error
    }
})

export const getDevicesInfo=createAsyncThunk('getDevicesInfo',async(payload)=>{
    try{
        const res=await axios.get(baseURL+`/devices/getdeviceinfo/${payload}`)     
        return res.data
    }catch(error){
        throw error
    }
})

export const getSearchData=createAsyncThunk('getSearchData',async(payload)=>{
    try{
        const res=await axios.get(baseURL+`/devices/searchdevice?deviceName=${payload.value}&${payload?`pageSize=${payload.pageSize}&pageIndex=${payload.pageIndex}`:""}`)
        return res.data
    }catch(error){
        throw error
    }
})

export const getAddData=createAsyncThunk('getAddData',async(payload)=>{
    try{
        const res=await axios.post(baseURL+'/devices/add',payload)
        return res.data
    }catch(error){
        throw error
    }
})

export const getDeviceID=createAsyncThunk('getDeviceId',async(payload)=>{
    try{
        const res=await axios.get(baseURL+`/devices/getdevicename?deviceName=${payload}`)
        return res.data
    }catch(error){
        throw error
    }
})