import AnalyticsHeader from './AnalyticsHeader';
import { Typography, Card, Spinner,Chip } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import { useEffect,useState } from 'react';

const Analytics = () => {

  const [tableData, setTableData] = useState();
  const state = useSelector((state) => state.AnalyticsSlice);

  useEffect(() => {
    if(state?.analyticData?.result?.finalData) setTableData(state?.analyticData?.result?.finalData)
   else setTableData()
  }, [state]);

  // 2710028594184SS
  return (
        <div className="py-5">
        <Typography variant="h2" className="text-center">System Analytics</Typography>
        <AnalyticsHeader />
        <Card className="mx-3 overflow-x-auto mt-10 mb-5">
          <table className="w-full min-w-full table-auto text-center ">
            <thead className="border-b border-blue-gray-100 bg-cyan-900 text-white p-4">
              <th className="py-3">Event Source</th>
              
              <th>Date & Time</th>
              <th>Event Type</th>
              <th>Payload</th>
            </thead>
            <tbody>
              {!state.loading?(tableData&&tableData.length>0 ?tableData.map((item, i) => {
                const options= { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'};
                const formattedDate=new Date(item.createdAt).toLocaleString('en-IN',options);
                  return (
                    <tr key={i} className="font-bold">
                      <td className="py-4 flex justify-center">
                        {item.eventSource === "subscribe" ?(
                          <Chip  color="indigo" value="Subscribe" />
                      ):  (
                        <Chip  color="teal" value="Publish"/>)
                    
                      }</td>
                      <td>{formattedDate}</td>
                      <td className="py-4 flex justify-center">{item.eventType === "connected" ? (
                        <Chip variant='ghost' color="green" value="Connected"/>
                      ) : item.eventType === "disconnected" ? (
                        <Chip variant='ghost' color="red" value="Disconnected"/>
                      ) : (
                        <Chip variant='ghost' color="cyan" value="Update"/>
                      )}
                      
                      </td>
                      <td>{item.payload?JSON.stringify(item.payload):'No payload'}</td>
                    </tr>
                 );
                }):<tr className='text-center text-2xl h-24 w-24'>
                  <td colSpan={4}>No Data Found</td></tr>):
                  <tr  className=' h-24 w-24'>
                    <td colSpan={4}><div className='flex justify-center'><Spinner className='h-12 w-12'/></div></td></tr>}
            </tbody>
          </table>
        </Card>
    </div>
  )
}

export default Analytics