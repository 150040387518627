import { createAsyncThunk } from "@reduxjs/toolkit";
import {axios} from "../../utils/axios"
import { baseURL } from "../../constants/defaultValues";

export const loginThunk=createAsyncThunk('loginThunk',async(payload)=>{
    try{
        const res=await axios.post(baseURL+'/auth/adminlogin',payload)       
        return res.data 
    }catch(error){
        throw error
    }
})


