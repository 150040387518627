import { createSlice } from "@reduxjs/toolkit";
import { CreateElectician, getallElectician,searchElectician } from "../thunk/electricianmanagement";
import toast from "react-hot-toast";

const initialState = {
    data: null,
    loading: false,
    error: null,
  };


const electricianSlice=createSlice({
    name:'Electrician_Slice',
    initialState,
    extraReducers:builder=>{
        builder.addCase(getallElectician.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(getallElectician.fulfilled,(state,action)=>{
            return {...state,allElectrician:action.payload,loading:false}
        });
        builder.addCase(getallElectician.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(searchElectician.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(searchElectician.fulfilled,(state,action)=>{
            toast.success("Successfully fethced data")
            return {...state,searchElectrician:action.payload,loading:false}
        });
        builder.addCase(searchElectician.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(CreateElectician.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(CreateElectician.fulfilled,(state,action)=>{
            toast.success("Electrician Added sucessfully")
            return {...state,searchElectrician:action.payload,loading:false}
        });
        builder.addCase(CreateElectician.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })
    }
})

export default electricianSlice.reducer;
