export const env=process.env.REACT_APP_ENV||'local'

let backendApi;

switch(env){
  case 'dev':
    backendApi='https://api.dev.teconico.in/admin';
    break;
  case 'prod':
    backendApi='https://api.teconico.in/admin';
    break;
  default:
    backendApi='http://localhost:8000/admin'
}

export const baseURL=backendApi


