import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  getallElectician} from "../../redux/thunk/electricianmanagement";
import { Typography, Card, Spinner } from "@material-tailwind/react";
import ElectricianManagementHeader from "./ElectricianManagementHeader";
import AddElectrician from "../../components/modal/Electrician/AddElectrician";
import { Pagination } from "../../components/common/Pagination";

export const ElectricianManagement = () => {
  const [tableData, setTableData] = useState();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.ElectricianSlice);

  const [openAddmodal, setOpenAddmodaL] = useState(false);
  const [currentPage,setCurrentPage]=useState(1)
  const [tableCount,setTableCount]=useState()
  const pageSize=5

  useEffect(() => {
    setTableData(state?.allElectrician?.electrician);
    setTableCount(state?.allElectrician?.count)
  }, [state?.allElectrician]);

  useEffect(() => {
    dispatch(getallElectician({currentPage,pageSize}));
    
  }, [currentPage]);

  return (
      <div className="py-5">
        <Typography variant="h2" className="text-center">Electrician Management</Typography>
        <ElectricianManagementHeader openAddmodal={openAddmodal} setTableData={setTableData} pageIndex={currentPage} setCurrentPage={setCurrentPage}
        onClose={() => {setOpenAddmodaL(true);}} setTableCount={setTableCount} currentPage={currentPage}/>
        <Card className="mx-3 overflow-x-auto mt-10 mb-5">
          <table className="w-full min-w-full table-auto text-center ">
            <thead className="border-b border-blue-gray-100 bg-cyan-900 text-white p-4">
              <th className="py-3">Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Address</th>
              <th>City</th>
              <th>State</th>
              <th>Pincode</th>
            </thead>
            <tbody>
              {!state.loading?(tableData && tableData.length > 0 ? tableData.map((item, i) => {
                  return (
                    <tr key={i} className="font-bold">
                      <td className="py-4">{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.mobileNumber1}</td>
                      <td>{item.address}</td>
                      <td>{item.city}</td>
                      <td>{item.state}</td>
                      <td>{item.pin}</td>
                    </tr>
                  );
                }):<tr className='text-center text-2xl h-24 w-24'>
                <td colSpan={7}>No Data Found</td></tr>):<tr className=' h-24 w-24'>
              <td colSpan={7}><div className='flex justify-center'><Spinner className='h-12 w-12' /></div></td></tr>}
            </tbody>
          </table>
          {tableCount>pageSize && <div className="flex justify-center my-10"><Pagination pageSize={pageSize} totalCount={tableCount} currentPage={currentPage} setCurrentPage={setCurrentPage}></Pagination></div>}
        </Card>
        <AddElectrician visible={openAddmodal} onClose={() => {setOpenAddmodaL(false)}} currentPage={currentPage}/>
      </div>
  );
};
