import { searchUser } from '../../redux/thunk/usermanagement';
import { useDispatch, useSelector} from "react-redux";
import { Button, Input } from '@material-tailwind/react';
import { IoIosSearch } from "react-icons/io";
import { useEffect,useState } from 'react';
import { userTypeOptions, customStyles } from '../../constants/contantValues';
import { getallUsers } from '../../redux/thunk/usermanagement';
import Select from 'react-select'

export const UserHeader = ({onClose,setTableData,pageIndex,pageSize,setCurrentPage,setTableCount}) => {

  const [selectedUserType ,setSelectedUserType] = useState()
  const [searchData,setSearchData]=useState("")

  const state=useSelector(state=>state.UserSlice?.searchUser)
  const dispatch=useDispatch();

  useEffect(()=>{
    setTableData(state?.result?.admins)
    setTableCount(state?.result?.count)
},[state?.result])

  const searchHandler=(e)=>{
    if (e.key === "Enter") {
      setCurrentPage(1)
      setSelectedUserType()
      setSearchData(e.target.value)
      if(pageIndex===1) dispatch(searchUser({value:e.target.value,pageIndex:1,pageSize:5}));
    }
  }

  useEffect(()=>{
    if(selectedUserType) {
      setSearchData("")
     dispatch(getallUsers({type:'user',userRole:selectedUserType.value,pageIndex,pageSize}))
    }
    else if(searchData){
      setSelectedUserType()
      dispatch(searchUser({value:searchData,pageIndex,pageSize:5}));
    }
    else  dispatch(getallUsers({pageIndex,pageSize}))
    },[selectedUserType,pageIndex])

    useEffect(()=>{
      setCurrentPage(1)
    },[selectedUserType])

  return (
    <div className="flex flex-col justify-between md:flex-row pt-10 px-16">
    <div className='flex flex-col md:flex-row gap-3'>
      <Input className='bg-white' onKeyDown={searchHandler} label='Search' color='blue' size='lg' icon={<IoIosSearch size={19}/>}/>
        <Select options={userTypeOptions} value={selectedUserType} placeholder="Filter User Type" onChange={(e)=>setSelectedUserType(e)}
        styles={{...customStyles,control: (provided) => ({...provided,height: '45px',width:'194px' }),}} isClearable/>
    </div>
    <div><Button color='blue' size='lg' onClick={onClose}>Add User</Button></div>
  </div>
  )
}
