import { createAsyncThunk } from "@reduxjs/toolkit";
import {axios} from "../../utils/axios"
import { baseURL, } from "../../constants/defaultValues";


export const getallUsers=createAsyncThunk('getallUsers',async(payload)=>{
    try{
        
        const query= `${(payload&&payload.type==='user')?'?userRole='+payload.userRole+"&":''}`
        const res=await axios.get(baseURL+`/users/getalladmins${(payload&&payload.type)?query:'?'}${payload?`pageSize=${payload.pageSize}&pageIndex=${payload.pageIndex}`:""}`)
        return res.data
    }catch(error){
        throw error
    }
})

export const searchUser=createAsyncThunk('searchUser',async(payload)=>{
    try{
        const res=await axios.get(baseURL+`/users/searchadmin?query=${payload.value}&${payload?`pageSize=${payload.pageSize}&pageIndex=${payload.pageIndex}`:""}`)
        return res.data
    }catch(error){
        throw error
    }
})

export const updateUser=createAsyncThunk('updateUser',async(userData)=>{
    try{
        const res=await axios.patch(baseURL+`/users/updateadmininfo/${userData.id}`,userData.payload)
        return res.data
    }catch(error){
        throw error
    }
})

export const addUser=createAsyncThunk('addUser',async(payload)=>{
    try{
        const res=await axios.post(baseURL+'/users/addadmin',payload)
        return res.data
    }catch(error){
        throw error
    }
})

export const blockUnblockUser=createAsyncThunk('blockUnblockUser',async(payload)=>{
    try{
        const res=await axios.patch(baseURL+`/users/access/${payload.id}`,{isBlocked:payload.blockStatus})
        return res.data
    }catch(error){
        throw error
    }
})
