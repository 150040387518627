import { useDispatch } from "react-redux";
import { Dialog, DialogBody, DialogHeader, Input, Button } from "@material-tailwind/react";
import { CreateElectician, getallElectician } from '../../../redux/thunk/electricianmanagement';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useState } from "react";

const AddElectrician = ({ visible, onClose,currentPage }) => {

  const [showPassword,setShowPassword]=useState(false)
  
  const dispatch = useDispatch();

  const submitHandler =async (e) => {
    e.preventDefault();

    const payload = {
      name: e.target[0].value,
      mobileNumber1: e.target[1].value,
      mobileNumber2: e.target[2].value,
      email: e.target[3].value,
      password: e.target[4].value,
      address: e.target[3].value,
      city: e.target[4].value,
      state: e.target[5].value,
      pin: e.target[6].value,
    };
    await dispatch(CreateElectician(payload));
    dispatch(getallElectician({currentPage,pageSize:5}));
    onClose();
  }
  return (
    <div>
      <Dialog open={visible} handler={onClose}>
        <DialogHeader>Add Electrician</DialogHeader>
        <DialogBody>
          <form
            className="grid grid-cols-1 gap-4 sm:grid-cols-2"
            onSubmit={submitHandler}
          >
            <Input label="Name" size="lg" color="blue" />
            <Input label="Mobile" size="lg" color="blue" type="number" />
            <Input label="Alternate Mobile" size="lg" color="blue" type="number" />
            <Input label="Email" size="lg" color="blue" type="email" />
            <Input label='Password' size="lg" color="blue" type={showPassword?"text":"password"} icon={
                showPassword ? (
                  <AiOutlineEyeInvisible onClick={()=>setShowPassword(!showPassword)} className="cursor-pointer"/>
                ) : (
                  <AiOutlineEye onClick={()=>setShowPassword(!showPassword)} className="cursor-pointer"/>
                )
              }/>
            <Input label="Address" size="lg" color="blue" />
            <Input label="City" size="lg" color="blue" />
            <Input label="State" size="lg" color="blue" />
            <Input label="Pincode" size="lg" color="blue" />
            <div className="col-span-2  mt-3 flex justify-center">
              <Button color="indigo" type="submit">Submit</Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>
    </div>
  )
}

export default AddElectrician;
