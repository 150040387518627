import { useEffect, useState } from "react";
import ServiceManagementHeader from "./requestManagementHeader";
import { Button, Typography, Card, Chip, Spinner } from "@material-tailwind/react";
import AddService from "../../components/modal/RequestManagement/addRequest";
import AssignElectrician from "../../components/modal/RequestManagement/assign";
import { useSelector } from "react-redux";
import { Pagination } from "../../components/common/Pagination";


export const RequestManagement = () => {
  const [openAddmodal, setOpenAddmodaL] = useState(false);
  const [openAssignmodal, setOpenAssignmodal] = useState(false);
  const [tableData, setTableData] = useState();
  const [tableCount,setTableCount]=useState()
  const [requestID,setRequestID]=useState('')
  const [currentPage,setCurrentPage]=useState(1)

  const state = useSelector((state) => state.RequestSlice);
  const pageSize=5

  useEffect(() => {
    setTableData(state?.requestData?.requests);
    setTableCount(state?.requestData?.count)
  }, [state?.requestData]);

  return (
      <div className="py-5">
        <Typography variant="h2" className="text-center">Request Management</Typography>
        <ServiceManagementHeader openAddmodal={openAddmodal} onClose={() => {setOpenAddmodaL(true)}} pageIndex={currentPage} setTableCount={setTableCount}
        setTableData={setTableData} currentPage={currentPage} pageSize={pageSize} setCurrentPage={setCurrentPage}/>
        <Card className="mx-3 overflow-x-auto mt-10 mb-5">
          <table className="w-full min-w-full table-auto text-center ">
            <thead className="border-b border-blue-gray-100 bg-cyan-900 text-white p-4"> 
              <th>Customer Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th className="py-3">Request Type</th>
              <th>Serial Number</th>
              <th>City</th>
              <th>State</th>
              <th>Electrician Name</th>
              <th>Electrician Mobile</th>
              <th>Assign To Electrician</th>
            </thead>
            <tbody>
              {!state.loading?(tableData && tableData.length > 0 ?  tableData.map((item, i) => {
                  return (
                    <tr key={i} className="font-bold"> 
                      <td>{item.customerName}</td>
                      <td>{item.email}</td>
                      <td>{item.mobile}</td>
                      <td className="py-4 flex justify-center">
                        {item.type === "INSTALLATION" ? (
                          <Chip variant="ghost"  className="ml-3" value="Installation" color="indigo" />
                        ) : (
                          <Chip variant="ghost" className="ml-3" value="Service" color="amber" />
                        )}
                      </td>
                      <td>{item.serialNumber}</td>
                      <td>{item.city}</td>
                      <td>{item.state}</td>
                      {/* <td className="py-5 font-bold">{item.pincode}</td> */}
                      <td>{item.electricianAssigned?item.electricianAssigned.name:'-'}</td>
                      <td>{item.electricianAssigned?item.electricianAssigned.mobileNumber1:'-'}</td>
                      <td>
                        {!item.electricianAssigned?<Button onClick={() => { setRequestID(item._id)
                            setOpenAssignmodal(true);}} color="amber" > Assign</Button>:<Chip color="blue" variant='ghost' value='Already Assigned'/>}
                      </td>
                    </tr>
                  );
                }):<tr className='text-center text-2xl h-24 w-24'>
                <td colSpan={10}>No Data Found</td></tr>):<tr className=' h-24 w-24'><td colSpan={10}><div className='flex justify-center'><Spinner className='h-12 w-12'/></div></td></tr>}
            </tbody>
          </table>
          {  tableCount>pageSize&& <div className="flex justify-center my-10">  <Pagination pageSize={pageSize} totalCount={tableCount} currentPage={currentPage} setCurrentPage={setCurrentPage}/></div>}
        </Card>

        {/* Modals */}
        
        <AddService visible={openAddmodal} onClose={() => {setOpenAddmodaL(false); }} currentPage={currentPage}/>
        <AssignElectrician visible={openAssignmodal} requestID={requestID} onClose={() => {setOpenAssignmodal(false);}} currentPage={currentPage}/>
      </div>
  );
};
