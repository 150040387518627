import { createAsyncThunk } from "@reduxjs/toolkit";
import {axios} from "../../utils/axios"
import { baseURL, } from "../../constants/defaultValues";

export const GetAllRequests=createAsyncThunk('getAllService',async(payload)=>{
    try{  
        const query=`${payload&&payload.type==='request'?'?type='+payload.request+"&":(payload&&payload.type==='electrician'?'?electrician='+payload.electrician+"&":'')}`
        const res=await axios.get(baseURL+`/service/allRequests${payload.type?query:"?"}pageSize=${payload.pageSize}&pageIndex=${payload.currentPage}`)
        return res.data
    }catch(error){
        throw error
    }
})

export const searchRequests=createAsyncThunk('searchRequests',async(payload)=>{
    try{
        const res=await axios.get(baseURL+`/service/searchRequests?query=${payload.value}&${payload?`pageSize=${payload.pageSize}&pageIndex=${payload.pageIndex}`:""}`)
        return res.data
    }catch(error){
        throw error
    }
})

export const addRequest=createAsyncThunk('addRequest',async(payload)=>{
    try{
        const res=await axios.post(baseURL+'/service/newRequest',payload)
        return res.data
    }catch(error){
        throw error
    }
})

export const assignElectrician=createAsyncThunk('assignElectrician',async(payload)=>{
    try{
        const res=await axios.post(baseURL+'/service/assignToElectrician',payload)
        return res.data
    }catch(error){
        throw error
    }
})
