import { useEffect, useState } from 'react';
import { UserHeader } from './UserHeader';
import { Typography, Card, Chip, Tooltip, Spinner } from "@material-tailwind/react";
import {  useSelector } from 'react-redux';
import AddUser from '../../components/modal/User/AddUser';
import { MdAccountBalance, MdAdminPanelSettings, MdBlock } from "react-icons/md";
import { IoCall, IoShield } from "react-icons/io5";
import { RiAdminFill } from "react-icons/ri";
import { FaPen } from "react-icons/fa6";
import { GoPasskeyFill } from "react-icons/go";
import Password from '../../components/modal/User/Password';
import Update from '../../components/modal/User/Update';
import BlockUnblock from '../../components/modal/User/BlockUnblock';
import UserPermission from '../../components/modal/User/UserPermission';
import { Pagination } from '../../components/common/Pagination';

export const User = () => {

  const [tableData, setTableData] = useState();
  const [tableCount,setTableCount]=useState()
  const [openAddUser, setOpenAddUser] = useState(false);
  const [openShieldUser,setOpenShieldUser] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openBlockUnblock,setOpenBlockUnblock] = useState(false);
  const [userData,setUserData]=useState('')
  const [userPermission, setUserPermission] = useState(false);
  const [currentPage,setCurrentPage]=useState(1)

  const state = useSelector((state) => state.UserSlice);

  const pageSize=5

  useEffect(() => {
    setTableData(state?.allUsers?.admins);
    setTableCount(state?.allUsers?.count)
  }, [state?.allUsers?.admins]);


  const statusDisplay = (item) => {
    if (item.isBlocked) return <Chip variant='ghost' color='red' value='Blocked' />
    if (item.isActive) return <Chip variant='ghost' color='green' value='Active' />
    return <Chip variant='ghost' color='orange' value='Inactive' />
  }

  const userType = (item) => {
    if (item.userRole === 1) return <Chip  color='red' value={<p className='flex justify-center'><RiAdminFill size={14}  className='mr-2' />SuperAdmin</p>} />
    if (item.userRole === 2) return <Chip  color='teal' value={<p className='flex justify-center'><MdAdminPanelSettings size={16} className='mr-2' />Admin</p>} />
    if (item.userRole === 3) return <Chip color='blue' value={<p className='flex justify-center'><IoCall size={15} className='mr-2' />Telecaller</p>} />
    if (item.userRole === 4) return <Chip color='indigo' value={<p className='flex justify-center'><MdAccountBalance size={15} className='mr-2' />Account</p>} />
  }

  return (
    <div>
      <div className="py-5">
        <Typography variant="h2" className="text-center">User Management</Typography>
        <UserHeader setTableData={setTableData} onClose={() => { setOpenAddUser(true); }} setTableCount={setTableCount}
         pageSize={pageSize} pageIndex={currentPage} setCurrentPage={setCurrentPage}/>
        <Card className="mx-3 overflow-x-auto mt-10 mb-5" >
          <table className="w-full min-w-full table-auto text-center">
            <thead className="border-b border-blue-gray-100 bg-cyan-900 text-white p-4">
              <th>User Type</th>
              <th className="py-3">Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Alternate Mobile</th>
              <th>Status</th>
              <th>Last Login</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {!state.loading?(tableData && tableData.length > 0 ? tableData.map((item, i) => {
                return (
                  <tr key={i} className='font-bold'>
                    <td className="py-4 flex justify-center">{userType(item)}</td>
                    <td >{item.name}</td>
                    <td >{item.email}</td>
                    <td >{item.mobileNumber1||'-'}</td>
                    <td >{item.mobileNumber2||'-'}</td>
                    <td className="flex justify-center">{statusDisplay(item)}</td>
                    <td >{item.lastLogin||'-'}</td>
                    <td className='cursor-pointer flex justify-evenly'>
                      <Tooltip content='See Password'><p><IoShield  color='teal' onClick={()=>{setOpenShieldUser(true)
                      setUserData(item)}}/></p></Tooltip>
                      <Tooltip content='Update User'><p>
                      <FaPen  color='indigo' onClick={()=>{setOpenUpdate(true)
                      setUserData(item)}}/></p></Tooltip>
                      <Tooltip content='Block/Unblock User'><p><MdBlock color='red' onClick={()=>{setOpenBlockUnblock(true)
                      setUserData(item)}}/></p></Tooltip>      
                      <Tooltip content='User Permissions'><p><GoPasskeyFill color='orange' onClick={()=>{setUserPermission(true)}}/></p></Tooltip>
                    </td>

                  </tr>
                );
              }):<tr className='text-center text-2xl h-24 w-24'>
              <td colSpan={8}>No Data Found</td></tr>):<tr className=' h-24 w-24'>
              <td colSpan={8}><div className='flex justify-center'><Spinner className='h-12 w-12' /></div></td></tr>}
            </tbody>
          </table>
          {tableCount> pageSize && <div className="flex justify-center my-10"><Pagination pageSize={pageSize} totalCount={tableCount} currentPage={currentPage} setCurrentPage={setCurrentPage}/></div>}
        </Card>

        <AddUser visible={openAddUser} onClose={() => {setOpenAddUser(false)}} pageIndex={currentPage}/>
        <Password userData={userData} visible={openShieldUser} onClose={()=> {setOpenShieldUser(false)}} />
        <Update  userData={userData} visible={openUpdate} onClose={()=> {setOpenUpdate(false)}} currentPage={currentPage}/>
        <BlockUnblock  userData={userData} visible={openBlockUnblock} onClose={()=> {setOpenBlockUnblock(false)}} pageIndex={currentPage}/>
        <UserPermission visible={userPermission} onClose={()=>setUserPermission(false)}></UserPermission>
      </div>
    </div>
  )
}
