import { useDispatch, useSelector } from "react-redux";
import { GetAllRequests, searchRequests } from "../../redux/thunk/requestManagement";
import { useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { Button, Input } from "@material-tailwind/react";
import Select from 'react-select'
import { customStyles, customSimpleStyles, requestTypeOptions } from '../../constants/contantValues';
import { getallElectician } from "../../redux/thunk/electricianmanagement";

const ServiceManagementHeader = ({ onClose, setTableData,pageSize,currentPage ,setCurrentPage,setTableCount}) => {
  const [selectedRequest, setSelectedRequest] = useState()
  const [selectedElectrian, setSelectedElectrian] = useState()
  const [electricianname, setElectricianName] = useState()
  const [searchData,setSearchData]=useState("")

  const dispatch = useDispatch()
  const state = useSelector(state => state.RequestSlice.searchRequest)
  const electricianState = useSelector(state => state.ElectricianSlice.allElectrician)


  useEffect(() => {
    dispatch(getallElectician())
  }, [])

  useEffect(() => {
    if(state?.result)   {
      setTableData(state?.result?.result)
      setTableCount(state?.result?.count)
    }
  }, [state?.result])

  useEffect(()=>{
    if (electricianState?.electrician) {
      setElectricianName(electricianState?.electrician)
    }
  },[electricianState?.electrician])

  const searchHandler = (e) => {
    if (e.key === "Enter") {
      setCurrentPage(1)
      setSelectedElectrian()
      setSearchData(e.target.value)
      setSelectedRequest()
      if(currentPage===1) dispatch(searchRequests({value:e.target.value,pageIndex:1,pageSize:5}));
    }
  }

  useEffect(() => {
    if (selectedRequest) {setSearchData("");dispatch(GetAllRequests({ type: 'request', request: selectedRequest.value,pageSize,currentPage }))}
    else if (selectedElectrian) {setSearchData("");dispatch(GetAllRequests({ type: 'electrician', electrician: selectedElectrian._id ,pageSize,currentPage}))}
    else if(searchData)   dispatch(searchRequests({value:searchData,pageIndex:currentPage,pageSize:5}));
    else dispatch(GetAllRequests({pageSize,currentPage}))
  }, [selectedRequest, selectedElectrian,currentPage])

  
  useEffect(()=>{
    setCurrentPage(1)
  },[selectedRequest,selectedElectrian])

  return (
    <div className="flex flex-col justify-between lg:flex-row pt-10 px-16 ">
      <div className="rounded px-8 flex flex-col lg:flex-row  gap-3">
        <Input className='bg-white' onKeyDown={searchHandler} label='Search' color='blue' size='lg' icon={<IoIosSearch size={19} />} />
        <div className='flex flex-col lg:flex-row  gap-3 '>
          <Select options={requestTypeOptions} styles={customStyles} value={selectedRequest} onChange={e => {
            setSelectedRequest(e)
            setSelectedElectrian(null)
          }} placeholder="Filter Request" isClearable />
          <Select options={electricianname} isClearable value={selectedElectrian} styles={{ ...customSimpleStyles, option: (provided) => ({ ...provided, width: '180px', borderRadius: '8px', padding: '5px', margin: '5px', cursor: 'pointer' }) }}
            placeholder="Filter Electrician" onChange={e => {
              setSelectedElectrian(e)
              setSelectedRequest(null)
            }} getOptionValue={(option) => option._id} getOptionLabel={(option) => option.name} />
        </div>
      </div>
      <div ><Button color='blue' size='lg' onClick={onClose}>Add Request</Button></div>
    </div>
  )
}

export default ServiceManagementHeader;
