import { Dialog, DialogBody, DialogHeader, Select, Option, Button } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getallUsers } from '../../../redux/thunk/usermanagement';
import { assignToTelecaller, getallLead } from '../../../redux/thunk/leadmanagement';

const AssignTelecaller = ({ visible, onClose, lead,currentPage }) => {

  const [telecallerList,setTelecalerList]=useState('')
  const [selectedTelecaller,setSelectedTelecaller]=useState('')

  const dispatch=useDispatch()
  const state = useSelector((state) => state.UserSlice);

  useEffect(()=>{
    if(visible)  dispatch(getallUsers());
  },[visible])

  useEffect(()=>{
    if(state.allUsers?.admins) 
    {
      let telecallers=state?.allUsers?.admins.filter((item)=>{if(item?.userRole===3) return item})
      setTelecalerList(telecallers)
    }
  },[state?.allUsers?.admins])

  const submitHandler= async()=>{
    await dispatch(assignToTelecaller({leadId:lead._id,telecallerId:selectedTelecaller}))
    dispatch(getallLead({pageSize:5,currentPage}))
    onClose()
  }
 
  return (
      <Dialog open={visible} handler={onClose} size='sm'>
        <DialogHeader>Assign Telecaller</DialogHeader>
        <DialogBody className='flex gap-2'>
          <Select label='Telecallers' color='blue' >
          {telecallerList&&telecallerList.map(item=><Option onClick={()=>setSelectedTelecaller(item._id)}>{item.name}</Option>)}
          </Select>
          <Button color='blue' className='text-center' onClick={submitHandler}>Assign</Button>
        </DialogBody>
      </Dialog>
  )
}

export default AssignTelecaller;