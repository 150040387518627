import React from 'react';
import {Dialog,DialogBody,DialogHeader,Input,Button,} from "@material-tailwind/react";
import { Checkbox } from "@material-tailwind/react";

const UserPermission = ({visible, onClose}) => {
  return (
    <div>
        <Dialog open={visible} handler={onClose}>
        <DialogHeader>Check Permissions</DialogHeader>
        <DialogBody>
          {/* <form
            className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            <Input label="Customer Name" size="lg" color="blue" />
            <Input label="Mobile" size="lg" color="blue" type="number" />
            <Input label="Email" size="lg" color="blue" type="email" />
            
            <div className="col-span-2  mt-3 flex justify-center">
              <Button color="indigo" type="submit">Submit</Button>
            </div>
          </form> */}

          <table className='gap-4'>
            <tr>
              <td>Lead Management</td>
              <td> <Checkbox/></td>
            </tr>
              <tr> 
              <td>User Management</td>
              <td><Checkbox></Checkbox></td>
              </tr>
             <tr>
             <td>Device Management</td>
              <td><Checkbox></Checkbox></td>
             </tr>
          </table>
        </DialogBody>
      </Dialog>
    </div>
  )
}

export default UserPermission