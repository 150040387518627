import { createSlice } from "@reduxjs/toolkit";
import { addUser, getallUsers, updateUser, blockUnblockUser, searchUser } from "../thunk/usermanagement";
import toast from "react-hot-toast";

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const userSlice = createSlice({
    name: 'getallUser',
    initialState,
    extraReducers: builder => {

        builder.addCase(getallUsers.pending, (state) => {
            return { ...state, loading: true }
        });
        builder.addCase(getallUsers.fulfilled, (state, action) => {
            return { ...state, allUsers: action.payload, loading: false }
        });
        builder.addCase(getallUsers.rejected, (state) => {
            return { ...state, loading: false, error: "Something went wrong" }
        })

        builder.addCase(blockUnblockUser.pending, (state) => {
            return { ...state, loading: true }
        });
        builder.addCase(blockUnblockUser.fulfilled, (state, action) => {
            toast.success(`${action.payload.result.isBlocked?"Successfully Blocked User":"Successfully Unblocked User"}`)
            return { ...state, blockUnblockUser: action.payload, loading: false }
        });
        builder.addCase(blockUnblockUser.rejected, (state) => {
            return { ...state, loading: false, error: "Something went wrong" }
        })

        builder.addCase(addUser.pending, (state) => {
            return { ...state, loading: true }
        });
        builder.addCase(addUser.fulfilled, (state, action) => {
            toast.success("Added User Successfully!!!")
            return { ...state, addUser: action.payload, loading: false }
        });
        builder.addCase(addUser.rejected, (state) => {
            return { ...state, loading: false, error: "Something went wrong" }
        })


        builder.addCase(updateUser.pending, (state) => {
            return { ...state, loading: true }
        });
        builder.addCase(updateUser.fulfilled, (state, action) => {
            toast.success("Updated User Successfully!!!")
            return { ...state, updateUser: action.payload, loading: false }
        });
        builder.addCase(updateUser.rejected, (state) => {
            return { ...state, loading: false, error: "Something went wrong" }
        })


        builder.addCase(searchUser.pending, (state) => {
            return { ...state, loading: true }
        });
        builder.addCase(searchUser.fulfilled, (state, action) => {
            toast.success("Successfully fetched data!!!")
            return { ...state, searchUser: action.payload, loading: false }
        });
        builder.addCase(searchUser.rejected, (state) => {
            return { ...state, loading: false, error: "Something went wrong" }
        })

    }
})

export default userSlice.reducer;