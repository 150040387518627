import { useState } from "react";
import {Dialog,DialogBody,DialogHeader,Input,Button,Select,Option,Textarea} from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import {  GetAllRequests,  addRequest} from "../../../redux/thunk/requestManagement";

const AddService = ({ visible, onClose,currentPage }) => {
  const [requestType, setRequestType] = useState();

  const dispatch = useDispatch();

  const submitHandler = async(e) => {
    e.preventDefault();

    const payload = {
      type: requestType,
      customerName: e.target[1].value,
      mobile: e.target[2].value,
      email: e.target[3].value,
      address: e.target[4].value,
      city: e.target[5].value,
      state: e.target[6].value,
      pincode: e.target[7].value,
      serialNumber: e.target[8].value,
      problemStatement: e.target[9].value,
    };
    await dispatch(addRequest(payload));
    dispatch(GetAllRequests({pageSize:5,currentPage}))
    onClose();
  };

  return (
    <div>
      <Dialog open={visible} handler={onClose}>
        <DialogHeader>Add Request</DialogHeader>
        <DialogBody>
          <form
            className="grid grid-cols-1 gap-4 sm:grid-cols-2"
            onSubmit={submitHandler}
          >
            <Select label="Request Type" color="blue">
              <Option onClick={() => setRequestType("INSTALLATION")}>
                Installation
              </Option>
              <Option onClick={() => setRequestType("SERVICE")}>Service</Option>
            </Select>
            <Input label="Customer Name" size="lg" color="blue" />
            <Input label="Mobile" size="lg" color="blue" type="number" />
            <Input label="Email" size="lg" color="blue" type="email" />
            <Input label="Address" size="lg" color="blue" />
            <Input label="City/Town" size="lg" color="blue" />
            <Input label="State" size="lg" color="blue" />
            <Input label="Pin" size="lg" color="blue" />
            <Input label="Serial Number" size="lg" color="blue" />
            <span></span>
            {requestType === "service" && (
              <div className="col-span-2 flex">
              <Textarea size="lg" label="Problem Statement" color="blue"/>
              </div>
            )}
            <div className="col-span-2  mt-3 flex justify-center">
              <Button color="indigo" type="submit">Submit</Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default AddService;
