import {Card,Typography,List,ListItemPrefix,ListItem} from "@material-tailwind/react";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineHomeRepairService } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Electrician from "../assets/icons/Sidebar/electrcian.svg";
import ElecWhite from "../assets/icons/Sidebar/elecWhite.svg";
import IOT from "../assets/icons/Sidebar/iot.svg";
import IOTWhite from "../assets/icons/Sidebar/iot-white.svg";
import { PiUsersThree } from 'react-icons/pi'
import { FaRegUser } from 'react-icons/fa'
import {TbDeviceDesktopAnalytics} from 'react-icons/tb';

export const Sidebar = ({ children,open }) => {
  const { pathname } = useLocation();
  const className1 = `h-screen left-0  transition-all duration-500 fixed top-10 overflow-hidden rounded-tl-none rounded-bl-none w-[4.2rem] max-w-[10rem]  xl:max-w-[12rem] ${open?"lg:w-[12rem]":""} p-4 shadow-x shadow-blue-gray-900/5`;
  const className2 = "hidden";

  const activeClassName =
    "bg-cyan-900 focus:bg-cyan-900 hover:text-white hover:bg-cyan-900 focus:text-white text-white";
  const defaultClassName = "hover:bg-cyan-900  hover:text-white";

  const navigate = useNavigate();
  return (
    <div>
      <Card className={className1}>

        <List className="w-24 mt-8 min-w-fit">
          <ListItem
            onClick={() => navigate("/dashboard")}
            className={
              pathname === "/dashboard" ? activeClassName : defaultClassName
            }
          >
            <ListItemPrefix className="ml-1">
              <RxDashboard />
            </ListItemPrefix>
            <Typography>Dashboard</Typography>
          </ListItem>
          <ListItem
            onClick={() => navigate("/lead-management")}
            className={
              pathname === "/lead-management"
                ? activeClassName
                : defaultClassName
            }
          >
            <ListItemPrefix >
              <PiUsersThree size={20} />
            </ListItemPrefix>
            <Typography>Leads</Typography>
          </ListItem>
          <ListItem
            onClick={() => navigate("/request-management")}
            className={
              pathname === "/request-management"
                ? activeClassName
                : defaultClassName
            }
          >
            <ListItemPrefix className="ml-1">
              <MdOutlineHomeRepairService />
            </ListItemPrefix>
            <Typography>Requests</Typography>
          </ListItem>
          <ListItem
            onClick={() => navigate("/device-management")}
            className={
              pathname === "/device-management"
                ? activeClassName
                : defaultClassName
            }
          >
            <ListItemPrefix>
              <img
                src={pathname === "/device-management" ? IOTWhite : IOT}
                alt="iot"
                className="w-6 "
                color="white"
              />
            </ListItemPrefix>
            <Typography>Devices </Typography>
          </ListItem>
          <ListItem
            onClick={() => navigate("/electrician-management")}
            className={pathname === "/electrician-management" ? activeClassName : defaultClassName}
          >
            <ListItemPrefix>
              <img
                src={pathname === "/electrician-management" ? ElecWhite : Electrician}
                alt="electrician"
                className="w-6"
              />
            </ListItemPrefix>
            <Typography>Electricians</Typography>
          </ListItem>
          <ListItem
            onClick={() => navigate("/user-management")}
            className={pathname === "/user-management" ? activeClassName : defaultClassName}
          >
            <ListItemPrefix className="ml-1">
              <FaRegUser size={18}/>
            </ListItemPrefix>
            <Typography>Users</Typography>
          </ListItem>

          <ListItem
            onClick={() => navigate("/analytics-management")}
            className={pathname === "/analytics-management" ? activeClassName : defaultClassName}
          >
            <ListItemPrefix className="ml-1">
              
              <TbDeviceDesktopAnalytics size={18}></TbDeviceDesktopAnalytics>
            </ListItemPrefix>
            <Typography>Analytics</Typography>
          </ListItem>
        </List>

      </Card>
      <div className={`ml-[4.2rem] transition-all duration-500 ${open?"lg:ml-[12rem]":""}`}>{children}</div>
    </div>
  );
};
