import { createSlice } from "@reduxjs/toolkit";
import toast from 'react-hot-toast';
import { getAnalytics } from "../thunk/analytics";

const initialState = {
    data: null,
    loading: false,
    error: null,
  };

  const analyticsSlice=createSlice({
    name:'Analytics_Management',
    initialState,
    extraReducers:builder=>{
        builder.addCase(getAnalytics.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(getAnalytics.fulfilled,(state,action)=>{
            toast.success("Successfully fetched data!!!")
            return {...state,analyticData:action.payload,loading:false}
        });
        builder.addCase(getAnalytics.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })
    }
    })

    export default analyticsSlice.reducer;
    