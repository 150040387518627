import axios from "axios";
import toast from "react-hot-toast";
import { emitter } from "./EventEmitter";

axios.interceptors.request.use(
    config=>{
        const token=localStorage.getItem('token')
        config.headers['x-auth-token']=token

        return config
    },
    error=>{
        return Promise.reject(error)
    }
)

axios.interceptors.response.use(
    (response)=>{
        return response
    },
(error)=>{
    if(error.response.data.error.message==='Token has been expired. Kindly Relogin!') emitter.emit('logout')
        toast.error("Something went wrong!!!")
        return Promise.reject(error);
    }
)

export {axios}