import TechNiko from "../assets/icons/mob.png";
import { Navbar, Tooltip, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { emitter } from "../utils/EventEmitter";
import { FaBars } from 'react-icons/fa'
import user from '../assets/icons/user.png'

export const NavBar = ({ close }) => {
  const navigate = useNavigate()
  const className1 = "fixed top-0 z-10 h-14 max-w-full rounded-none px-4 py-2 lg:px-8 lg:py-2 text-black"

  const logoutHandler = () => {
    localStorage.removeItem('token')
    emitter.emit('logout')
  }

  return (
    <Navbar className={className1}>
      <div className="flex justify-between">
        <FaBars onClick={close} className="cursor-pointer mt-2" />
        <Typography
          variant="h2"
          className=" font-bold flex text-2xl text-custom-logo"
        >
          <img src={TechNiko} alt="techniko" className="h-8 mr-3 mt-1" />
          <span className="mt-1">TecoNico</span>
        </Typography>
        <Typography className="flex">
          <span className="mt-2">SuperAdmin</span> 
          <Tooltip content='Logout'><img src={user} className="w-8 ml-2 h-8 mt-1  cursor-pointer" onClick={logoutHandler} /></Tooltip>
        </Typography>
      </div>
    </Navbar>

  );
};
