import { createSlice } from "@reduxjs/toolkit";
import {addRequest, GetAllRequests, assignElectrician, searchRequests } from "../thunk/requestManagement";
import toast from "react-hot-toast";

const initialState = {
    data: null,
    loading: false,
    error: null,
  };

const requestSlice=createSlice({
    name:'Request_Management',
    initialState,
    extraReducers:builder=>{

        builder.addCase(GetAllRequests.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(GetAllRequests.fulfilled,(state,action)=>{
            return {...state,requestData:action.payload,loading:false}
        });
        builder.addCase(GetAllRequests.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(addRequest.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(addRequest.fulfilled,(state,action)=>{
            toast.success("Added Request Successfully!!!")
            return {...state,addRequestData:action.payload,loading:false}
        });
        builder.addCase(addRequest.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(assignElectrician.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(assignElectrician.fulfilled,(state,action)=>{
            toast.success("Assigned Electrician Successfully!!!")
            return {...state,assignElectrician:action.payload,loading:false}
        });
        builder.addCase(assignElectrician.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(searchRequests.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(searchRequests.fulfilled,(state,action)=>{
            toast.success("Successfully fetched data!!!")
            return {...state,searchRequest:action.payload,loading:false}
        });
        builder.addCase(searchRequests.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })
    }
})

export default requestSlice.reducer;