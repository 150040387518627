import React from 'react';
import './index.css';
import "@fontsource/manrope"
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from "@material-tailwind/react";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <ThemeProvider>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </ThemeProvider>
);
