import { createAsyncThunk } from "@reduxjs/toolkit";
import {axios} from "../../utils/axios"
import { baseURL } from "../../constants/defaultValues";

export const CreateElectician=createAsyncThunk('CreateElectician',async(payload)=>{
    try{
        const res=await axios.post(baseURL+'/electrician/save',payload)
        return res.data
    }catch(error){
        throw error
    }
})

export const getallElectician=createAsyncThunk('getallElectician',async(payload)=>{
    
    try{
        
        const res=await axios.get(baseURL+`/electrician${payload?`?pageSize=${payload.pageSize}&pageIndex=${payload.currentPage}`:""}`)
        return res.data
    }catch(error){
        throw error
    }
})

export const searchElectician=createAsyncThunk('searchElectician',async(payload)=>{
    try{
        const res=await axios.get(baseURL+`/electrician/search?query=${payload.value}&${payload?`pageSize=${payload.pageSize}&pageIndex=${payload.pageIndex}`:""}`)
        return res.data
    }catch(error){
        throw error
    }
})
