import { Dialog, DialogBody, DialogHeader, Input, Button } from "@material-tailwind/react";
import { getallLead, updateLead } from '../../../redux/thunk/leadmanagement';
import { useDispatch } from 'react-redux';

const UpdateLead = ({ visible, onClose,lead,currentPage }) => {
  
  const dispatch = useDispatch();
  const submitHandler = async(e) => {
    e.preventDefault();

    const payload = {
      id:lead._id,
      ...(e.target[0].value ? { name: e.target[0].value} : {}),
      ...(e.target[1].value ? { mobile: e.target[1].value} : {}),
      ...(e.target[2].value ? { email: e.target[2].value} : {})
    };
    await dispatch(updateLead(payload));
    dispatch(getallLead({pageSize:5,currentPage}))
    onClose();
  };
  return (
      <Dialog open={visible} handler={onClose} size="sm">
        <DialogHeader>Update Lead</DialogHeader>
        <DialogBody>
          <form onSubmit={submitHandler} className="flex flex-col gap-3">
            <Input defaultValue={lead.name} label="Customer Name" size="lg" color="blue" />
            <Input defaultValue={lead.mobile}  label="Mobile" size="lg" color="blue" type="number" />
            <Input defaultValue={lead.email}  label="Email" size="lg" color="blue" type="email" />
            <div className="flex justify-center mt-3">
              <Button color="indigo" type="submit">Submit</Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>
  )
}

export default UpdateLead;