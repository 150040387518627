import { Dialog, DialogBody, DialogHeader, Input, } from "@material-tailwind/react";

const Password = ({ visible, onClose, userData }) => {

  // console.log(btoa(userData.password))

  return (
    <div>
      <Dialog open={visible} handler={onClose} size='sm'>
        <DialogHeader>Password</DialogHeader>
        <DialogBody>
          <Input value={userData.password} label="Password" size="lg" color="blue" disabled />
        </DialogBody>
      </Dialog>
    </div>
  )
}

export default Password;
