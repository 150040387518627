import { useDispatch, useSelector } from "react-redux";
import { searchElectician } from "../../redux/thunk/electricianmanagement";
import { IoIosSearch } from "react-icons/io";
import { Input,Button } from "@material-tailwind/react";
import { useEffect, useState } from "react";

const ElectricianManagementHeader = ({onClose,setTableData,setTableCount,setCurrentPage,currentPage,pageIndex}) => {


  const [searchData,setSearchData]=useState("")
  const dispatch=useDispatch()
 const state=useSelector(state=>state.ElectricianSlice.searchElectrician)

 useEffect(() => {
  setTableData(state?.result?.result);
  setTableCount(state?.result?.count)
}, [state?.result]);

  const searchHandler=(e)=>{
    if (e.key === "Enter") {
      setCurrentPage(1)
      setSearchData(e.target.value)
      if(currentPage===1)  dispatch(searchElectician({value:e.target.value,pageIndex:1,pageSize:5}));
    }
  }


  useEffect(()=>{
    if(searchData) dispatch(searchElectician({value:searchData,pageIndex,pageSize:5}));
  },[currentPage])
  return (
    <div className="flex flex-col justify-between md:flex-row pt-10 px-16 ">
    <div className="rounded px-8 ">
    <Input className='bg-white' onKeyDown={searchHandler} label='Search' color='blue' size='lg' icon={<IoIosSearch size={19}/>}/>
    </div>
    <div >
    <Button  color='blue' size='lg' onClick={onClose}>Add Electrician</Button>
    </div>
  </div>
  )
}

export default ElectricianManagementHeader;
