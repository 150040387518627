import { createAsyncThunk } from "@reduxjs/toolkit";
import {axios} from "../../utils/axios"
import { baseURL, } from "../../constants/defaultValues";

export const getAnalytics=createAsyncThunk('getAnalytics',async(payload)=>{
    try{
        const res=await axios.get(baseURL+`/eventData/getEventData?deviceName=${payload.devicename}${payload.filter?'&startTime='+ 
        payload.startTime + '&endTime='+ payload.endTime : ""
    }`)
        return res.data
    }catch(error){
        throw error
    }
})