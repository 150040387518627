import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getallLead, searchLead } from '../../redux/thunk/leadmanagement';
import { IoIosSearch } from 'react-icons/io';
import { Button, Input } from '@material-tailwind/react';
import Select from 'react-select'
import { customStyles,customSimpleStyles,statusOptions } from '../../constants/contantValues';
import { getallUsers } from '../../redux/thunk/usermanagement';

const LeadManagementHeader = ({ onClose, setTableData,currentPage,pageSize,setCurrentPage,setTableCount }) => {
  const [selectedStatus, setSelectedStatus] = useState()
  const [selectedTelecaller, setSelectedTelecaller] = useState()
  const[telecaller,setTelecalerList]=useState()
  const [searchData,setSearchData]=useState("")

  const dispatch = useDispatch();

  const state = useSelector((state) => state.LeadSlice.searchLead);

  const telecallerState=useSelector(state=>state.UserSlice)

  useEffect(() => {
    dispatch(getallUsers());
  }, [])

  useEffect(()=>{
    setTableData(state?.result?.result);
    setTableCount(state?.result?.count)
  },[state?.result])

  useEffect(() => {

    if (telecallerState?.allUsers?.admins) {
      let telecallers = telecallerState?.allUsers?.admins.filter((item) => { if (item?.userRole===3) return item })
      setTelecalerList(telecallers)
    }
  }, [telecallerState?.allUsers?.admins]);


  const searchHandler = (e) => {
    if (e.key === "Enter") {
      setCurrentPage(1)
      setSelectedStatus()
      setSearchData(e.target.value)
      setSelectedTelecaller()
      if(currentPage===1)  dispatch(searchLead({value:e.target.value,pageIndex:1,pageSize:5}));
    }
  }

  useEffect(()=>{
    if(selectedStatus) {
      setSearchData("")
      dispatch(getallLead({type:'status',status:selectedStatus.value,currentPage,pageSize}))
    }
    else if(selectedTelecaller) {
      setSearchData("")
      dispatch(getallLead({type:'telecaller',telecaller:selectedTelecaller._id,currentPage,pageSize}))
    }
    else if(searchData){
      setSelectedStatus()
      setSelectedTelecaller()
      dispatch(searchLead({value:searchData,pageIndex:currentPage,pageSize:5}));
    }
    else dispatch(getallLead({currentPage,pageSize}))
    },[selectedStatus,selectedTelecaller,currentPage])

    useEffect(()=>{
      setCurrentPage(1)
    },[selectedStatus,selectedTelecaller])

  return (
    <div className="flex flex-col justify-between md:flex-row pt-10 px-16 ">
      <div className="rounded px-8 flex flex-col lg:flex-row  gap-3">
        <Input className='bg-white' onKeyDown={searchHandler}  label='Search' color='blue' size='lg' icon={<IoIosSearch size={19} />} />
      <div className='flex flex-col lg:flex-row  gap-3 '>
        <Select options={statusOptions}  styles={customStyles} value={selectedStatus} placeholder="Filter Status" onChange={(e)=>{setSelectedStatus(e)
        setSelectedTelecaller(null)}} isClearable />
        <Select options={telecaller}  styles={customSimpleStyles} value={selectedTelecaller} placeholder="Filter Telecaller" onChange={(e)=>{setSelectedTelecaller(e)
        setSelectedStatus(null)}} getOptionValue={(option) => option._id}  getOptionLabel={(option) => option.name} isClearable/>
      </div>
      </div>
      <div><Button color='blue' size='lg' onClick={onClose}>Add Lead</Button></div>
    </div>
  )
}

export default LeadManagementHeader
