import {  Dialog,DialogBody,DialogHeader,Button,Select,Option,Collapse} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeStatus, getallLead } from "../../../redux/thunk/leadmanagement";

const ChangeStatus = ({ visible, onClose,leadId,currentPage }) => {
  const [isStatus,setStatus]=useState('')
  const [showStatus, setShowStatus] = useState(false)

  const dispatch=useDispatch()
  const state=useSelector(state=>state.LeadSlice.leadData)

  useEffect(()=>{
    setShowStatus(false)
  },[visible])

  const statusHistory=state?.leads.filter(lead=>{if(lead._id===leadId) return lead})


  const statusHandler= async()=>{
    const payload={id:leadId , status :isStatus}
      await dispatch(changeStatus(payload))
    dispatch(getallLead({pageSize:5,currentPage}))
    onClose()
  }

  return (
      <Dialog open={visible} handler={onClose} size="sm" >
        <DialogHeader>Change Status</DialogHeader>
        <DialogBody>
            <Select label="Request Type" color="blue">
              <Option onClick={()=>setStatus('PENDING')}>Pending</Option> 
              <Option onClick={()=>setStatus('IN-TOUCH')}>In-Touch</Option>
              <Option onClick={()=>setStatus('CLOSED')}>Closed</Option>
            </Select>
            <div className="flex justify-center gap-3 mt-4">
              <Button color="cyan" onClick={() => setShowStatus(!showStatus)}>Status History</Button>
              <Button color="green" type="submit" onClick={statusHandler}>Submit</Button>
            </div>
            <Collapse open={showStatus} >
            <table className="w-full border border-collapse overflow-scroll  min-w-full table-auto text-center mt-3">
              <thead className="border-b border-blue-gray-100 bg-cyan-400 text-white p-4">
                <th className="border py-1">Status</th>
                <th className="border">Date</th>
              </thead>
              <tbody >
                {statusHistory && statusHistory[0]?.statusHistory.map((item,i)=>{
                    const formattedDate = new Date(item.createdAt).toLocaleString('en-IN');
                  return (
                    <tr key={i}>
                      <td>{item.status}</td>
                      <td>{formattedDate}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            </Collapse>
        </DialogBody>
      </Dialog>
  );
};

export default ChangeStatus;
