import { createAsyncThunk } from "@reduxjs/toolkit";
import {axios} from "../../utils/axios"
import { baseURL } from "../../constants/defaultValues";

export const dashboardThunk=createAsyncThunk('dashboardThunk',async(payload)=>{
    
    try{
        const res=await axios.get(baseURL+'/dashboard/getDashboardData',payload)       
        return res.data 
    }catch(error){
        throw error
    }
})


