export const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? state.data.select
        : state.isFocused
          ? state.data.color
          : undefined,
      borderRadius:'8px',
      padding:'5px',
      margin:'5px',
      width:'180px',
      cursor:'pointer',
      ':active': {
        ...provided[':active'],
        backgroundColor: state.isSelected
          ? state.data.color
          : undefined,
      },
    }),
    singleValue: (provided,state) => ({
      ...provided,
      backgroundColor: state.data.select,
      borderRadius:'8px',
      color:'white',
      textAlign:"center",
      paddingBlock:'3px',
    }),

    menu: (provided) => ({
      ...provided,
      textAlign:'center'
    }),
    control: (provided) => ({
      ...provided,
      height: '45px',
      width:'192px'
    }),
  };

  export const customSimpleStyles = {
    option: (provided, state) => ({
      ...provided,
      borderRadius:'8px',
      padding:'5px',
      margin:'5px',
      width:'198px',
      cursor:'pointer'
    }),
    menu: (provided) => ({
      ...provided,
      textAlign:'center'
    }),
    control: (provided) => ({
      ...provided,
      height: '45px',
      width:'208px'
    }),
  };

  export const statusOptions = [
    { value: 'PENDING', label: "Pending", color: "#ffeacc", select: '#FBCA85' },
    { value: 'IN-TOUCH', label: "In-Touch", color: '#dbefdc', select: '#7AF680' },
    { value: 'CLOSED', label: "Closed", color: '#fdd9d7', select: '#FB7D76' },
  ];

  export const requestTypeOptions = [
    { value: 'INSTALLATION', label: "Installation", color: "#d9dcf0", select: "#AEB7F0" },
    { value: 'SERVICE', label: "Service", color: '#fff3cd', select: '#F8E39D' },
  ];

  export const deviceOptions = [
    { label: '4 SWITCH', value: "1", color: "#ffeacc", select: '#FBCA85' },
    { label: '3 SWITCH 1 FAN',  value: "2", color: '#dbefdc', select: '#7AF680' },
    { label: '1 SWITCH',  value: "3", color: '#fdd9d7', select: '#FB7D76' },
  ];

  export const userTypeOptions = [
    { label: 'Account', value: 4, color: "#A4ACD1", select: '#3f51b5' },
    { label: 'Admin',  value: 2, color: '#799E9A', select: '#009688' },
    { label: 'Telecaller',  value: 3, color: '#BDD5E9', select: '#2196f3' },
  ];

  export const eventSourceOptions = [
    { label: 'Connected', value: "Connected", color: "#dbefdc", select: '#6EE474' },
    { label: 'Update',  value: "Update", color: '#ccf2f6', select: '#45E0F2' },
    { label: 'Disconnected',  value: "Disconnected", color: '#fdd9d7', select: '#F57872' },
  ];