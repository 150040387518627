import { createSlice } from "@reduxjs/toolkit";
import { loginThunk } from "../thunk/login";

const initialState = {
    data: null,
    loading: false,
    error: false,
  };


const loginSlice=createSlice({
    name:'login',
    initialState,
    extraReducers:builder=>{
        builder.addCase(loginThunk.pending,(state)=>{
            state.loading=true;
            state.error=false
        });
        builder.addCase(loginThunk.fulfilled,(state,action)=>{
            localStorage.setItem('token',action.payload?.result?.token)
            state.loading=true;
            state.data=action.payload
            state.error=false
        });
        builder.addCase(loginThunk.rejected,(state)=>{
            state.loading=false;
            state.error=true
        })
    }
})

export default loginSlice.reducer
