import { lazy, Suspense,useEffect,useState  } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate ,useLocation} from "react-router-dom";
import { AppLayout } from "../components/AppLayout.js";
import { ElectricianManagement } from "../views/ElectricianManagement/ElectricianManagement.js";
import Error from "../views/Error.js";
import { emitter } from "../utils/EventEmitter.js";
import { RequestManagement } from "../views/RequestManagement/requestManagement.js";
import LeadManagement from "../views/LeadManagement/LeadManagement.js";
import { User } from "../views/UserManagement/User.js";
import Analytics from "../views/Analytics/AnalyticsManagement.js";


const Login = lazy(() => import("../views/Login.js"));
const Dashboard = lazy(() => import("../views/Dashboard.js"));
const DeviceManagement = lazy(() =>
  import("../views/DeviceManagment/DeviceManagement.js")
);

export default function AppRoutes() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { data } = useSelector((state) => state.LoginReducer);

  const navigate = useNavigate();

  const location=useLocation()

  useEffect(() => {
    if (localStorage.getItem("token")) {
      setIsLoggedIn(true);
    }
  }, [data?.result?.token]);

  useEffect(() => {
    emitter.on("logout", () => {
      setIsLoggedIn(false);
      navigate("/")
    });
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    if (!isLoggedIn) navigate("/");
    else  navigate('/dashboard')
  }, [isLoggedIn]);

  if(!isLoggedIn&&location.pathname!=='/') navigate('/')

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
       { !isLoggedIn&&<Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="*" element={<Error />} />
        </Routes>}

        {isLoggedIn && (
          <AppLayout>
            <Routes>
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route
                exactF
                path="/device-management"
                element={<DeviceManagement />}
              />
              <Route
                exact
                path="/electrician-management"
                element={<ElectricianManagement />}
              />
              <Route
                exact
                path="/request-management"
                element={<RequestManagement />}
              />
              <Route
              exact
              path="/lead-management"
              element={<LeadManagement/>}>
              </Route>
              <Route
              exact
              path="/user-management"
              element={<User/>}>
              </Route>
              <Route
              exact
              path="/analytics-management"
              element={<Analytics></Analytics>}>
              </Route>
            </Routes>
          </AppLayout>
        )}
      </Suspense>
    </div>
  );
}
