import { createSlice } from "@reduxjs/toolkit";
import { getallLead,addLead, changeStatus, searchLead, updateLead,addComment,commentHistory, assignToTelecaller } from "../thunk/leadmanagement";
import toast from "react-hot-toast";

const initialState = {
    data: null,
    loading: false,
    error: null,
  };

const leadSlice=createSlice({
    name:'Lead_Management',
    initialState,
    extraReducers:builder=>{
        builder.addCase(getallLead.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(getallLead.fulfilled,(state,action)=>{
            return {...state,leadData:action.payload,loading:false}
        });
        builder.addCase(getallLead.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(addLead.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(addLead.fulfilled,(state,action)=>{
            toast.success("Successfully Added Lead!!!")
            return {...state,addLead:action.payload,loading:false}
        });
        builder.addCase(addLead.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })

        builder.addCase(changeStatus.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(changeStatus.fulfilled,(state,action)=>{
            toast.success("Successfully changed status!!!")
            return {...state,changeStatus:action.payload,loading:false}
        });
        builder.addCase(changeStatus.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(searchLead.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(searchLead.fulfilled,(state,action)=>{
            toast.success("Successfully fetched data!!!")
            return {...state,searchLead:action.payload,loading:false}
        });
        builder.addCase(searchLead.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })

        builder.addCase(updateLead.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(updateLead.fulfilled,(state,action)=>{
            toast.success("Updated Lead Successfully!!!")
            return {...state,updateLead:action.payload,loading:false}
        });
        builder.addCase(updateLead.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(addComment.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(addComment.fulfilled,(state,action)=>{
            toast.success("Added Comment Successfully!!!")
            return {...state,addComment:action.payload,loading:false}
        });
        builder.addCase(addComment.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })

        
        builder.addCase(commentHistory.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(commentHistory.fulfilled,(state,action)=>{
            return {...state,commentHistory:action.payload,loading:false}
        });
        builder.addCase(commentHistory.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(assignToTelecaller.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(assignToTelecaller.fulfilled,(state,action)=>{
            toast.success("Assigned Telecaller Successfully!!!")
            return {...state,assignToTelecaller:action.payload,loading:false}
        });
        builder.addCase(assignToTelecaller.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })

    }
})

export default leadSlice.reducer;