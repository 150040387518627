import { Dialog, DialogBody, DialogHeader, Input, Button, Select, Option } from "@material-tailwind/react";
import { useDispatch } from 'react-redux';
import { addUser, getallUsers } from '../../../redux/thunk/usermanagement';
import { useState } from "react";

const AddUser = ({ visible, onClose ,pageIndex}) => {

  const dispatch = useDispatch();
  const [isUserType, setIsUserType] = useState();
  
  const submitHandler = async (e) => {
    e.preventDefault();

    const payload = {
      name: e.target[1].value,
      mobileNumber1: e.target[2].value,
      mobileNumber2 :e.target[3].value,
      email: e.target[4].value,
      userrole:isUserType,
      password: e.target[5].value,
    };
    await dispatch(addUser(payload));
    dispatch(getallUsers({pageIndex,pageSize:5}))
    onClose();
  }

  return (
    <div>
      <Dialog open={visible} handler={onClose}>
        <DialogHeader>Add User</DialogHeader>
        <DialogBody>
          <form
            className="grid grid-cols-1 gap-4 sm:grid-cols-2"
            onSubmit={submitHandler}
          >
              <Select label="User Type" color="blue">
              <Option onClick={()=>setIsUserType(2)}> Admin</Option>
              <Option onClick={()=>setIsUserType(3)}>Telecaller</Option>
              <Option onClick={()=>setIsUserType(4)}>Account</Option>
            </Select>
            <Input label="Name" size="lg" color="blue" />
            <Input label="Mobile" size="lg" color="blue" type="number" />
            <Input label="Alternate Mobile" size="lg" color="blue" type="number" />
            <Input label="Email" size="lg" color="blue" type="email" />
            <Input label="Password" size="lg" color="blue" type='password' />
            <div className="col-span-2  mt-3 flex justify-center">
              <Button color="indigo" type="submit">Submit</Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>
    </div>
  )
}

export default AddUser;