import { createSlice } from "@reduxjs/toolkit";
import toast from 'react-hot-toast'
import { getAddData,getAllDevices, getDeviceID, getDevicesInfo, getSearchData } from "../thunk/devicemanagment";

const initialState = {
    data: null,
    loading: false,
    error: null,
  };

const deviceSlice=createSlice({
    name:'Device_Management',
    initialState,
    extraReducers:builder=>{
        builder.addCase(getAllDevices.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(getAllDevices.fulfilled,(state,action)=>{
            return {...state,deviceData:action.payload,loading:false}
        });
        builder.addCase(getAllDevices.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(getAddData.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(getAddData.fulfilled,(state,action)=>{
            toast.success("Successfully added device!!!")
            return {...state,addDevice:action.payload,loading:false}
        });
        builder.addCase(getAddData.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })

        builder.addCase(getDevicesInfo.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(getDevicesInfo.fulfilled,(state,action)=>{
            return {...state,selectedDeviceInfo:action.payload,loading:false}
        });
        builder.addCase(getDevicesInfo.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(getDeviceID.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(getDeviceID.fulfilled,(state,action)=>{
            return {...state,deviceID:action.payload,loading:false}
        });
        builder.addCase(getDeviceID.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })


        builder.addCase(getSearchData.pending,(state)=>{
            return {...state,loading:true}
        });
        builder.addCase(getSearchData.fulfilled,(state,action)=>{
            toast.success("Successfully fetched data!!!")
            return {...state,searchData:action.payload,loading:false}
        });
        builder.addCase(getSearchData.rejected,(state)=>{
            return {...state,loading:false,error:"Something went wrong"}
        })
    }
})

export default deviceSlice.reducer;
