import { IoIosSearch } from "react-icons/io";
import { Button, Input } from "@material-tailwind/react";
import { getAnalytics } from '../../redux/thunk/analytics';
import { useDispatch } from 'react-redux';
import { useState } from "react";
import { customStyles } from "../../constants/contantValues";
import Select from 'react-select';
import { eventSourceOptions } from "../../constants/contantValues";

const AnalyticsHeader = () => {

  const dispatch = useDispatch();
  const [selectedEventType ,setSelectedEventType] = useState()
  const [selectedDevice, setSelectedDevice] = useState()
  const [showCalender, setShowCalender] = useState();

  const toggleVisibility = () => {
    setShowCalender(!showCalender);
  };
  
  const searchHandler = (e) => {
    if (e.key === "Enter") {
      setSelectedDevice(e.target.value)
      const payload = {
       devicename: e.target.value,
       filter : false    
   }
      dispatch(getAnalytics(payload));
      toggleVisibility();
    }
  }

  const submitHandler = (e) =>{
      e.preventDefault()
      const payload = {
      startTime: new Date(e.target[0].value).getTime(), 
      endTime : new Date(e.target[1].value).getTime(),
      devicename: selectedDevice,
      filter :(e.target[0].value && e.target[1].value) ? true : false
  }
    dispatch(getAnalytics(payload))
  }

  return (
    <div className="flex flex-col justify-between md:flex-row pt-10 px-16 ">
      <div className="rounded px-8 flex flex-col md:flex-row  gap-3">
        <Input className='bg-white' onKeyDown={searchHandler} label='Search' color='blue' size='lg' icon={<IoIosSearch size={19} /> }
        />

         <Select options={eventSourceOptions} value={selectedEventType} placeholder="Filter Event Type" onChange={(e)=>setSelectedEventType(e)}
        styles={{...customStyles,control: (provided) => ({...provided,height: '45px',width:'220px' }),}} isClearable/> 
      </div>
      {(showCalender &&
    
      <form className='flex flex-col md:flex-row  gap-3' onSubmit={submitHandler}>
       
      <Input className='bg-white' type='date' label='Start Date' size='lg' />
      <Input className='bg-white' type='date' label='End Date' size='lg'/>
      <Button type="submit" color="primary" size="lg">Filter</Button>
      </form>
      )}
    </div>
  )
}

export default AnalyticsHeader;