import { Dialog, DialogBody, DialogHeader, Input, Button } from "@material-tailwind/react";
import { useDispatch } from 'react-redux';
import { getallUsers, updateUser } from "../../../redux/thunk/usermanagement";

const Update = ({ visible, onClose,userData,pageIndex }) => {

  const dispatch = useDispatch();
  const submitHandler = async(e) => {
    e.preventDefault();

    const payload = {
      ...(e.target[1].value ? { name: e.target[1].value} : {}),
      ...(e.target[2].value ? { mobileNumber1: e.target[2].value} : {}),
      ...(e.target[3].value ? { mobileNumber2: e.target[3].value} : {}),
      ...(e.target[4].value ? { email: e.target[4].value} : {}),
      ...(e.target[5].value ? {password: e.target[5].value} :{})
    };
    await dispatch(updateUser({payload,id:userData._id}));
    dispatch(getallUsers({pageIndex,pageSize:5}))
    onClose();
  }

  const roleChecker=(role)=>{
    if(role===1) return 'Superadmin'
    else if(role===2) return "Admin"
    else if(role===3) return "Telecaller"
    else if(role===4) return "Account"
  }


  return (
    <div>
      <Dialog open={visible} handler={onClose} >
        <DialogHeader>Update User</DialogHeader>
        <DialogBody>
          <form className="grid grid-cols-1 gap-4 sm:grid-cols-2"  onSubmit={submitHandler} >
            <Input defaultValue={roleChecker(userData?.userRole)} label="User Type"  size="lg" color="blue" disabled/>
            <Input defaultValue={userData.name} label="Name" size="lg" color="blue" />
            <Input defaultValue={userData.mobileNumber1} label="Mobile" size="lg" color="blue" type="number" />
            <Input defaultValue={userData.mobileNumber2} label="Alternate Mobile" size="lg" color="blue" type="number" />
            <Input defaultValue={userData.email} label="Email" size="lg" color="blue" />
            <Input defaultValue={userData.password} label="Password" size="lg" color="blue" type='password' />
            <div className="col-span-2  mt-3 flex justify-center"><Button color="indigo" type="submit">Submit</Button></div>
          </form>
        </DialogBody>
      </Dialog>
    </div>
  )
}

export default Update;