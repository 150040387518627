import { Typography, Card, Chip, Tooltip, Spinner } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import LeadManagementHeader from "./LeadManagementHeader";
import AddLead from "../../components/modal/LeadManagement/AddLead";
import ChangeStatus from "../../components/modal/LeadManagement/ChangeStatus";
import UpdateLead from "../../components/modal/LeadManagement/UpdateLead";
import Comments from "../../components/modal/LeadManagement/Comments";
import AssignTelecaller from "../../components/modal/LeadManagement/AssignTelecaller";

import { FaComment } from "react-icons/fa";
import updateIcon from '../../assets/icons/leads/update.png'
import changeStatus from '../../assets/icons/leads/status.png'
import telecaller from '../../assets/icons/leads/telecaller.png'
import {Pagination } from "../../components/common/Pagination";


const LeadManagement = () => {
  const leadData = useSelector((state) => state.LeadSlice);
  const [selectedLead, setSelectedLead] = useState('')

  const [tableData, setTableData] = useState();
  const [tableCount,setTableCount]=useState()
  const [addModal, setAddModal] = useState(false);

  const [changeModal, setChangeModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);


  const [currentPage,setCurrentPage]=useState(1)
  const pageSize=5


  useEffect(() => {
   setTableData(leadData?.leadData?.leads);
   setTableCount(leadData?.leadData?.count)
  }, [leadData?.leadData]);

  return (
    <>
      <div className="py-5">
        <Typography variant="h2" className="text-center">Lead Management </Typography>
      <LeadManagementHeader addModal={addModal} onClose={() => { setAddModal(true); }} setTableCount={setTableCount}
         setTableData={setTableData} currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize}/>
        <Card className="mx-3 overflow-x-auto mt-10 mb-5">
          <table className="w-full min-w-full  table-auto text-center ">
            <thead className="border-b border-blue-gray-100 bg-cyan-900 text-white p-4">
              <th className="py-3">Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Mobile</th>
              <th>Assigned Telecaller</th>
              <th>Actions</th>
            </thead>
            <tbody>
              
              {!leadData.loading?(tableData && tableData.length > 0 ? tableData.map((item, i) => {
                return (
                  <tr key={i} className="font-bold">
                    <td >{item.name || "-"}</td>
                    <td >{item.email || "-"}</td>
                    <td className="py-4 flex justify-center">
                      {item.status.toLowerCase() === "in-touch" ? (
                        <Chip variant='ghost' color="green" value="In-Touch" />
                      ) : item.status.toLowerCase() === "closed" ? (
                        <Chip variant='ghost' color="red" value="Closed" />
                      ) : (
                        <Chip variant='ghost' color="orange" value="Pending" />
                      )}
                    </td>
                    <td>{item.mobile || "-"}</td>
                    <td >{item.telecallerAssigned?item.telecallerAssigned.assignedTelecaller.name:<Chip variant="ghost" value='Not Yet Assigned'/>}</td>
                    <td className='cursor-pointer flex justify-evenly'>
                      <Tooltip content='Add Comment'><p><FaComment className='text-blue-400'onClick={() => {
                        setCommentModal(true)
                        setSelectedLead(item._id)
                      }} /></p></Tooltip>
                      <Tooltip content='Update Lead'><img src={updateIcon} onClick={() => {
                        setUpdateModal(true)
                        setSelectedLead(item)
                      }} className="w-5" /></Tooltip>
                      <Tooltip content='Change Status'><img src={changeStatus} onClick={() => {
                        setChangeModal(true)
                        setSelectedLead(item._id)
                      }} className="w-5" /></Tooltip>
                      {!item.telecallerAssigned&&<Tooltip content='Assign to telecaller'><img src={telecaller} onClick={() => {
                        setAssignModal(true)
                        setSelectedLead(item)
                      }} className="w-5" /></Tooltip>}
                    </td>
                  </tr>
                );
              }):<tr className='text-center text-2xl h-24 w-24'>
              <td colSpan={6}>No Data Found</td></tr>):<tr className=' h-24 w-24'><td colSpan={6}><div className='flex justify-center'><Spinner className='h-12 w-12'/></div></td></tr>}
            </tbody>
          </table>
       {  tableCount>pageSize&& <div className="flex justify-center my-10"><Pagination pageSize={pageSize} totalCount={tableCount} currentPage={currentPage} setCurrentPage={setCurrentPage}/></div>}
         
        </Card>

        <AddLead visible={addModal} onClose={() => {setAddModal(false); }} currentPage={currentPage}/>
        <Comments leadId={selectedLead} visible={commentModal} onClose={() => { setCommentModal(false); }} currentPage={currentPage}/>
        <ChangeStatus leadId={selectedLead} visible={changeModal} onClose={() => { setChangeModal(false); }} currentPage={currentPage}/>
        <UpdateLead lead={selectedLead} visible={updateModal} onClose={() => { setUpdateModal(false); }} currentPage={currentPage}/>
        <AssignTelecaller lead={selectedLead} visible={assignModal} onClose={() => { setAssignModal(false); }} currentPage={currentPage}/>
      </div>
    </>
  );
};

export default LeadManagement;
