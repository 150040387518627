import { createSlice } from "@reduxjs/toolkit";
import { dashboardThunk } from "../thunk/dashboard";

const initialState = {
    data: null,
    loading: false,
    error: false,
  };


const dashboardSlice=createSlice({
    name:'dashboard',
    initialState,
    extraReducers:builder=>{
        builder.addCase(dashboardThunk.pending,(state)=>{
            state.loading=true;
            state.error=false
        });
        builder.addCase(dashboardThunk.fulfilled,(state,action)=>{
            state.loading=true;
            state.data=action.payload
        });
        builder.addCase(dashboardThunk.rejected,(state)=>{
            state.loading=false;
            state.error=true
        })
    }
})

export default dashboardSlice.reducer;
