import { NavBar } from "./Navbar";
import { Sidebar } from "./Sidebar";
import { useState } from "react";
import { Toaster } from "react-hot-toast";

export const AppLayout = ({ children }) => {
  const [open, setOpen] = useState(true);
  return (
    <>
    <Toaster  position="top-right" 
    toastOptions={{
      success: {
        style: { background: '#61d345', color:'white' },
        iconTheme:{ primary:"#27D05A" }
      },
      error: {
        style: { background: '#D03D27',color:'white' },
      },
    }}/>
      <NavBar close={() => setOpen(!open)}/>
      <Sidebar open={open} >
        <div className="bg-custom-grey h-full min-h-screen mt-14">{children}</div>
      </Sidebar>
    </>
  );
};
