import { Button, IconButton } from "@material-tailwind/react";
import { FaArrowLeft,FaArrowRight } from "react-icons/fa6";
 
export const Pagination=({pageSize,totalCount,currentPage,setCurrentPage}) =>{

  const totalPageCount = Math.ceil(totalCount / pageSize);
  if(totalPageCount===1) return

  const pageNumbers=[]
  for (let i = 1; i <= totalPageCount; i++) {
    pageNumbers.push(i);
}
 
  const getStyles = (index) => { 
  return ({
      variant: currentPage === index ? "filled" : "text",
      color: "gray",
      onClick: () => setCurrentPage(index),
    });}
 
  const next = () => {
    if (currentPage === totalPageCount) return;
 
    setCurrentPage(currentPage + 1);
  };
 
  const prev = () => {
    if (currentPage === 1) return;
 
    setCurrentPage(currentPage - 1);
  };

 
  return (
    <div className="flex items-center gap-4">
      <Button
        variant="text"
        className={`flex items-center gap-2 text-black`}
        onClick={prev}
        color="blue"
        disabled={currentPage === 1}
      >
        <FaArrowLeft strokeWidth={2} className="h-4 w-4" /> Previous
      </Button>
      <div className="flex items-center gap-2">
        {pageNumbers.map(item=>{ return <IconButton {...getStyles(item)} color="blue"><p className={`${currentPage===item?"text-white":"text-black"}`}>{item}</p></IconButton>})}
      </div>
      <Button
        variant="text"
        className="flex items-center gap-2 text-black"
        onClick={next}
        color="blue"
        disabled={currentPage === totalPageCount}
      >
        Next
        <FaArrowRight strokeWidth={2} className="h-4 w-4" />
      </Button>
    </div>
  );
}