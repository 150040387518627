import { Dialog, DialogBody, DialogHeader, Button,Select,Option, Chip, Typography } from "@material-tailwind/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { blockUnblockUser, getallUsers } from "../../../redux/thunk/usermanagement";

const BlockUnblock = ({visible,onClose,userData,pageIndex}) => {
  const [blockStatus,setBlockStatus]=useState(false)

  const dispatch=useDispatch()

  const blockHandler=async()=>{
   await  dispatch(blockUnblockUser({id:userData._id,blockStatus}))
    dispatch(getallUsers({pageIndex,pageSize:5}))
    onClose()
  }
  return (
    <div>
        <Dialog open={visible} handler={onClose} size='xs'>
        <DialogHeader>Block/Unblock User</DialogHeader>
        <DialogBody>
          <Typography className='flex mb-3 gap-2'>Currently:{userData.isBlocked?<Chip variant='ghost' value='blocked' color='red'/>:<Chip variant='ghost' value='Unblocked' color='green'/>}</Typography>
          <Select label="Request Type" color="blue">
              <Option onClick={()=>setBlockStatus(true)}>Block</Option>
              <Option onClick={()=>setBlockStatus(false)}>Unblock</Option> 
            </Select>
            <div className='flex justify-center'><Button color="indigo" onClick={blockHandler} className='mt-3'>Submit</Button></div>
        </DialogBody>
      </Dialog>
    </div>
  )
}

export default BlockUnblock;