import { Dialog, DialogBody, DialogHeader,Select,Option, Button} from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getallElectician } from '../../../redux/thunk/electricianmanagement';
import { GetAllRequests, assignElectrician } from '../../../redux/thunk/requestManagement';

const Assign = ({visible, onClose,requestID,currentPage}) => {

  const [assignedElectrician,setAssignedElectrician]=useState('')
  const [electrcianList,setElectricianList]=useState('')

  const dispatch=useDispatch()
  const state=useSelector(state=>state.ElectricianSlice.allElectrician)

  useEffect(()=>{
    if(visible)   {
      dispatch(getallElectician())
    }
  
  },[visible])

  useEffect(()=>{
    if(state?.electrician){
      setElectricianList(state?.electrician)
    }
  },[state?.electrician])

  const assignToElectrician= async()=>{
    if(assignedElectrician){
      const payload={
        electricianId:assignedElectrician,
        requestId:requestID
      }
      await dispatch(assignElectrician(payload))
      dispatch(GetAllRequests({pageSize:5,currentPage}))
      onClose()
    }
  }

  return (
       <Dialog open={visible} handler={onClose} size='sm'>
        <DialogHeader>Assign Electrician</DialogHeader>
        <DialogBody className='flex gap-2'>
            <Select label='Electricians' color='blue' >
           {electrcianList&&electrcianList.map(item=><Option onClick={()=>setAssignedElectrician(item._id)}>{item.name}</Option>)}
          </Select>
          <Button color='blue' className='text-center' onClick={assignToElectrician}>Assign</Button>
        </DialogBody>
    </Dialog>
  )
}

export default Assign;
