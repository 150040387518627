import {Dialog,DialogBody,DialogHeader,Input,Button,} from "@material-tailwind/react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { addLead, getallLead } from "../../../redux/thunk/leadmanagement";

const AddLead = ({ visible, onClose ,currentPage}) => {

  const dispatch=useDispatch()

  const submitHandler=async (e)=>{
    e.preventDefault()

    const payload={
      ...(e.target[0].value ? { name: e.target[0].value} : {}),
      ...(e.target[1].value ? { mobile: e.target[1].value} : {}),
      ...(e.target[2].value ? { email: e.target[2].value} : {})
    }
    if(e.target[1].value||e.target[2].value){
      await dispatch(addLead(payload))
      dispatch(getallLead({pageSize:5,currentPage}))
      onClose()
    }else{
      toast.error("Please add either email or mobile")
    }

  }


  return (
    <div>
        <Dialog open={visible} handler={onClose}>
        <DialogHeader>Add Lead</DialogHeader>
        <DialogBody>
          <form
            className="grid grid-cols-1 gap-4 sm:grid-cols-2"
            onSubmit={submitHandler}>
            <Input label="Customer Name" size="lg" color="blue" />
            <Input label="Mobile" size="lg" color="blue" type="number" />
            <Input label="Email" size="lg" color="blue" type="email" />
            {/* <Input label="Product" size="lg" color="blue" /> */}
            <div className="col-span-2  mt-3 flex justify-center">
              <Button color="indigo" type="submit">Submit</Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>
    </div>
  )
}

export default AddLead